import React, { useState } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { PaymentSourceSelection } from "@swbc/swivel-components";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, LoadingIndicator } from "../../../components";
import Title from "../../../components/Title";
import {
  selectPaymentMethodTypes,
  useSelectPaymentMethodDispatch
} from "../../../context";
import { deleteAccount } from "../../../services/accounts/api/deleteAccount.js";
import { useGoogleAnalytics } from "../../../services/analytics/hooks/useGoogleAnalytics.js";
import { useAccountsAndCardsData, useHandleDeleteSavedPaymentMethod } from "../../../services/payments/lib/hooks";
import { deleteCard } from "../../../services/storedCards/api/deleteCard.js";
import { routeMap } from "../../../utils/data/routeMap";
import { formatCardExpiryDate, sortAccountsAndCards } from "../../../utils/helpers/accountsAndCardsHelpers";

const AddOrEditPaymentMethod = () => {
  const institutionState = (state) => state.institution;

  const { config, details } = useSelector(institutionState);

  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const { actions, categories, trackGtagEvent } = useGoogleAnalytics();

  const selectPaymentDispatch = useSelectPaymentMethodDispatch();

  const {
    data: accountsAndCardsData,
    refetch,
    isLoading: accountsAndCardsLoading,
    isRefetching: accountsAndCardRefetching
  } = useAccountsAndCardsData(setError);

  const navigate = useNavigate();

  const cardMutation = useMutation({
    mutationFn: deleteCard,
    onSuccess: async () => {
      trackGtagEvent(actions.DELETE_STORED_CARD, categories.ATTEMPT_SUCCESS);
      setSelectedTab("card");
      await refetch();
      setPaymentMethodId("");
    },
    onError: (error) => {
      setSelectedTab("card");
      setPaymentMethodId("");
      trackGtagEvent(actions.DELETE_STORED_CARD, categories.ATTEMPT_FAILURE);
      setError(error);
    }
  });

  const achMutation = useMutation({
    mutationFn: deleteAccount,
    onSuccess: async () => {
      setSelectedTab("ach");
      await refetch();
      setPaymentMethodId("");
    },
    onError: (error) => {
      setSelectedTab("ach");
      setPaymentMethodId("");
      setError(error);
    }
  });

  const deleteSavedPaymentMethod = useHandleDeleteSavedPaymentMethod({ error, setError, cardMutation, achMutation, setPaymentMethodId });

  const onClickContinue = () => {
    navigate("/profile");
  };

  const onClickAddBank = () => {
    const entryPoint = routeMap.get("/profile");
    selectPaymentDispatch({
      type: selectPaymentMethodTypes.SAVE_ENTRY_POINT,
      value: entryPoint
    });
    navigate("/connect-bank");
  };

  const onClickAddCard = () => navigate("/save-card");

  const isCardEnabled = details?.services?.payments?.card?.enabled || details?.card_payment_enabled;
  const isAchEnabled = details?.services?.payments?.ach?.enabled || details?.ach_payment_enabled;

  const paymentMethods = [
    {
      id: 1,
      type: "card",
      label: "Card",
      icon: <CreditCardIcon />,
      enabled: isCardEnabled
    },
    {
      id: 2,
      type: "ach",
      label: "Bank",
      icon: <AccountBalanceIcon />,
      enabled: isAchEnabled
    }
  ];

  const { accounts, cards } = sortAccountsAndCards(accountsAndCardsData?.accounts?.results, accountsAndCardsData?.cards?.results);

  const cardList = cards?.map((item) => {
    const date = formatCardExpiryDate(item.card_details.expiration_month, item.card_details.expiration_year);

    return {
      id: item?.id,
      paymentType: "card",
      cardBrand: item?.display_name,
      cardExpiry: date,
      primaryText: item?.display_name,
      secondaryText: item?.card_details?.card_last4,
      details: {
        ...item,
        label: item?.display_name,
        type: "card",
        last_4: item?.card_details?.card_last4
      }
    };
  });

  const bankList = accounts?.map((item) => {
    return {
      id: item?.id,
      paymentType: "ach",
      primaryText: item?.banking_details?.name_of_bank,
      secondaryText: item?.last_4,
      details: {
        ...item,
        label: item?.banking_details?.name_of_bank,
        type: "ach"
      }
    };
  });

  return (
    <>
      <Title
        title="Add or Edit Payment Methods"
      />
      {(accountsAndCardsLoading || accountsAndCardRefetching || cardMutation.isLoading || achMutation.isLoading) ? (
        <LoadingIndicator />
      ) : (
        <>
          <Alert message={cardMutation?.isSuccess && cardMutation?.data?.message} severity={cardMutation?.status} />
          <Alert message={achMutation?.isSuccess && achMutation?.data?.message} severity={achMutation?.status} />
          <Alert message={error?.message} severity={error?.severity} />
          <PaymentSourceSelection
            onDelete={deleteSavedPaymentMethod}
            onClickAddBank={onClickAddBank}
            onClickAddCard={cardList.length < 5 ? onClickAddCard : null}
            onClickContinue={onClickContinue}
            paymentMethods={paymentMethods}
            cardList={cardList}
            bankList={bankList}
            priority={selectedTab || config?.services?.payments?.priority}
            loadingItemId={paymentMethodId}
            selectable={false}
            buttonText="Back to Profile"
          />
        </>
      )}
    </>
  );
};
export default AddOrEditPaymentMethod;
