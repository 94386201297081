import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { TransactionProvider, SelectPaymentMethodProvider } from "../context";
import Accounts from "../pages/Accounts/Accounts";
import AuthorizeAutopay from "../pages/AuthorizeAutoPay";
import { SetupAutoPay } from "../pages/AutopaySetup";
import ConnectBank from "../pages/ConnectBank";
import { DeeplinkLogin } from "../pages/DeeplinkLogin";
import { EditAutopaySchedule } from "../pages/EditAutopaySchedule";
import ErrorPage from "../pages/Error/ErrorPage";
import ExpressPay from "../pages/ExpressPay/ExpressPay";
import IdLookup from "../pages/IdLookup";
import LinkBankAccount from "../pages/LinkBankAccount/LinkBankAccount";
import Login from "../pages/Login/Login";
import AddAccount from "../pages/NonValidationAddAccounts/AddAccount";
import NonValidationAddAccount from "../pages/NonValidationAddAccounts/NonValidationAddAccounts";
import CreatePassword from "../pages/Password/CreatePassword";
import NonValidationCreatePassword from "../pages/Password/NonValidationCreatePassword";
import MakePayment from "../pages/Payment/MakePayment";
import PaymentConfirmedPage from "../pages/Payment/PaymentConfirmedPage";
import PaymentHistory from "../pages/PaymentHistory/PaymentHistory";
import PendingPayments from "../pages/PendingPayment/PendingPaymentPage";
import { Profile } from "../pages/Profile/Profile";
import { ProfileChangeEmail } from "../pages/ProfileChangeEmail/ProfileChangeEmail";
import { ProfileChangePassword } from "../pages/ProfileChangePassword/ProfileChangePassword";
import Register from "../pages/Register";
import RegisterGuest from "../pages/RegisterGuest";
import CheckEmail from "../pages/ResetPassword/CheckEmail/index";
import Request from "../pages/ResetPassword/Request";
import ResetPassword from "../pages/ResetPassword/Reset";
import ReviewPayment from "../pages/ReviewPayment";
import SsoTimeout from "../pages/SsoTimeout";
import { StateOfResidence } from "../pages/StateOfResidence";
import SuccessPage from "../pages/Success/index";
import { REGISTER_NEW_USER } from "../utils/constants/constants";
import AddCardPage from "../views/Payments/AddCard";
import AddOrEditPaymentMethod from "../views/Payments/AddOrEdit";
import PaymentConfirmed from "../views/Payments/Confirmed";
import OneTime from "../views/Payments/OneTime";
import PendingPayment from "../views/Payments/Pending";
import ReviewPaymentPage from "../views/Payments/Review";
import SaveCardPage from "../views/Payments/SaveCard";
import SelectPaymentMethod from "../views/Payments/Select";
import { ProtectedRoute } from "./ProtectedRoute";

const RouterConfig = ({ authToken, config, loan, user, redirectionPath, shouldClearBreadcrumbs }) => {

  const authState = (state) => state.auth;
  const paymentState = (state) => state.payment;

  const { register: { nonValidation } } = useSelector(authState);
  const { manuallyLinkFlow, loanType } = useSelector(paymentState);
  const { isNextGenPayments } = useFlags();
  const isApplePayEnabled = config?.services?.digital_wallets?.apple_pay?.enabled;
  const useNextGenPayments = isNextGenPayments || isApplePayEnabled;
  const uniqueIdLookup = config?.services?.unique_id_lookup;

  return (
    <Routes>
      {/* Public Routes */}
      {
        config?.express_pay_only
          ?
          <>
            <Route index path="/express-pay" element={<ExpressPay />} />
            <Route path="/" element={<ExpressPay />} />
          </>
          :
          <>
            <Route index path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/check-email" element={<CheckEmail />} />
            <Route path="/reset-password" element={<Request />} />
            <Route path="/success/password-reset" element={
              <SuccessPage
                title="Success!"
                subTitle="Your password has been reset. Please login with your new password."
              />
            }
            />
            {config?.express_pay_enabled ? <Route path="/express-pay" element={<ExpressPay />} /> : null}
          </>
      }
      {
        uniqueIdLookup?.enabled && (uniqueIdLookup?.express_pay?.enabled || uniqueIdLookup?.registered?.enabled)
          ? <Route path="/id-lookup" element={<IdLookup />} />
          : null
      }
      <Route path="/deeplink-login" element={<DeeplinkLogin />} />
      <Route path="/sso-timeout" element={<SsoTimeout />} />

      <Route element={<ProtectedRoute isAllowed={nonValidation?.payload} redirectPath={`/login?institutionId=${config?.id}`} />}>
        <Route path="/non-validation/create-password" element={<NonValidationCreatePassword />} />
      </Route>

      {/* Protected Routes: Must have auth token  */}
      <Route element={<ProtectedRoute isAllowed={authToken} redirectPath={redirectionPath} />}>
        <Route path="/gate/reset-password" element={<ResetPassword />} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/history" element={<PaymentHistory />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/add-accounts" element={<NonValidationAddAccount />} />
        <Route path="/add-or-edit-account" element={<AddAccount />} />
        <Route element={<ProtectedRoute isAllowed={authToken && !user?.from_sso} redirectPath="/profile" />}>
          <Route path="/profile/password" element={<ProfileChangePassword />} />
          <Route path="/profile/email" element={<ProfileChangeEmail />} />
        </Route>
        <Route path="/profile/state-of-residence" element={<StateOfResidence />} />
        <Route path="/register/guest" element={<RegisterGuest />} />

        {/* TODO: Need to add logic for isAllowed */}
        <Route element={<ProtectedRoute
          isAllowed={authToken} redirectPath="/accounts" />
        }>
          <Route path="/connect-bank" element={
            <TransactionProvider>
              <SelectPaymentMethodProvider>
                <ConnectBank />
              </SelectPaymentMethodProvider>
            </TransactionProvider>
          }
          />
        </Route>

        <Route element={<ProtectedRoute
          isAllowed={(authToken && loan) || (authToken && !loan && manuallyLinkFlow?.route === "/profile")}
          redirectPath="/accounts"
        />}>
          <Route path="/link-bank-account" element={<LinkBankAccount />} />
        </Route>

        {/* Protected Routes: Must have an auth token and a loan set in redux for payment flow */}
        <Route element={<ProtectedRoute isAllowed={authToken && loan} redirectPath="/accounts" />}>
          <Route path="/autopay/setup" element={<SetupAutoPay />} />
          <Route path="/autopay/authorize" element={<AuthorizeAutopay />} />
        </Route>

        {/* Feature Flag: When merchant is properly registered, use new payment flow at same routes
        Protected Routes: Must have an auth token and a loan set in redux for payment flow */}
        {useNextGenPayments ? (
          <Route element={<ProtectedRoute isAllowed={authToken && loan} redirectPath="/accounts" />}>
            {/* TODO: switch out MakePayment component with new page */}
            <Route path="/make-payment" element={<TransactionProvider>
              <SelectPaymentMethodProvider>
                <OneTime />
              </SelectPaymentMethodProvider>
            </TransactionProvider>} />
            <Route path="/select-payment-method" element={<TransactionProvider>
              <SelectPaymentMethodProvider>
                <SelectPaymentMethod />
              </SelectPaymentMethodProvider>
            </TransactionProvider>} />
            <Route path="/add-card" element={<TransactionProvider>
              <SelectPaymentMethodProvider>
                <AddCardPage />
              </SelectPaymentMethodProvider>
            </TransactionProvider>} />
            <Route path="/review-payment" element={<TransactionProvider>
              <ReviewPaymentPage />
            </TransactionProvider>} />
            <Route path="/pending-payments" element={<TransactionProvider>
              <PendingPayment />
            </TransactionProvider>} />
          </Route>
        ) : (
          <Route element={<ProtectedRoute isAllowed={authToken && loan} redirectPath="/accounts" />}>
            <Route path="/make-payment" element={<MakePayment />} />
            <Route path="/review-payment" element={<ReviewPayment />} />
            <Route path="/pending-payments" element={<PendingPayments />} />
          </Route>
        )}


        {/* Feature Flag: When merchant is properly registered, use new payment flow at same routes
        Protected Routes: Must have an auth token */}
        {useNextGenPayments ? (
          <Route element={<ProtectedRoute isAllowed={authToken} redirectPath="/accounts" />}>
            <Route path="/add-or-edit-payment-method" element={
              <TransactionProvider>
                <SelectPaymentMethodProvider>
                  <AddOrEditPaymentMethod />
                </SelectPaymentMethodProvider>
              </TransactionProvider>
            } />
            <Route path="/save-card" element={<SaveCardPage />} />
          </Route>
        ) : null}

        {/* Feature Flag: When merchant is properly registered, use new payment flow at same routes
        Protected Routes: Must have an auth token and a loanType set in redux for payment flow */}
        {useNextGenPayments ? (
          <Route element={<ProtectedRoute isAllowed={(authToken && loanType)} redirectPath="/accounts" />}>
            <Route path="/payment-confirmed" element={<TransactionProvider>
              <PaymentConfirmed />
            </TransactionProvider>} />
          </Route>
        ) : (
          <Route element={<ProtectedRoute isAllowed={(authToken && loanType)} redirectPath="/accounts" />}>
            <Route path="/payment-confirmed" element={<PaymentConfirmedPage />} />
          </Route>
        )}

        {/* Protected Routes: Must have an auth token and a loan with and autopay set in redux for autopay flow */}
        <Route element={<ProtectedRoute isAllowed={authToken && loan?.autopay} redirectPath="/accounts" />}>
          <Route path="/autopay/edit" element={<EditAutopaySchedule />} />
        </Route>

        {/* Protected Routes: Must have shouldClearBreadcrumbs */}
        <Route element={<ProtectedRoute isAllowed={shouldClearBreadcrumbs} redirectPath={redirectionPath} />}>
          <Route path="/create-password" element={<CreatePassword />} />
          <Route path="/success/registration" element={
            <SuccessPage
              title="Successful Registration!"
              subTitle="Please click Continue to login to Accounts and Loans."
              flow={REGISTER_NEW_USER}
            />
          } />
        </Route>
      </Route>

      <Route path="*" element={<ErrorPage />} />
    </Routes >
  );
};

export default RouterConfig;
