import React, { Fragment, useEffect, useRef, useState, useMemo } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  FormControlLabel,
  useTheme
} from "@mui/material";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Typography, MenuItem, Calendar, Checkbox } from "../../components";
import { Summary } from "../../components/custom/features/Summary";
import Title from "../../components/Title";
import { ButtonElement } from "../../services/sdk/components/ButtonElement.js";
import { setActivity } from "../../store/activity/activity.slice";
import { setAutoPayDetails, setIsAchVerificationComplete } from "../../store/payment/makePayment.slice";
import { getSavedAccounts, patchSaveAccount, setFulfilledAccounts } from "../../store/savedAccounts/savedAccounts.slice";
import { REGISTERED, REGISTER_NEW_USER } from "../../utils/constants/constants";
import { validateAmount } from "../../utils/helpers/cardHelpers";
import { validateIsBusinessDay } from "../../utils/helpers/dayjsHelpers";
import { getPaymentConfig, getAutopayConvenienceFee } from "../../utils/helpers/getPaymentConfig";
import { getAccountLastFour } from "../../utils/helpers/handleAccounts";
import { paymentAmountInputMask } from "../../utils/helpers/paymentAmountInputMask";
import { useMultipleFormErrors } from "../../utils/hooks/useMultipleFormErrors";
import { useNonBankingDays } from "../../utils/hooks/useNonBankingDays";
import { validate } from "../../utils/validators/validators";
import FinicityService from "../Payment/MakePayment/ach/finicity/FinicityService";
import AchModals from "../Payment/MakePayment/ach/modals";
import { Form } from "./styles";

export const SetupAutoPay = () => {
  // Named selectors
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;
  const paymentState = (state) => state.payment;
  const achVerificationState = (state) => state.achVerification;
  const savedAccountsState = (state) => state.savedAccounts;
  const loansState = (state) => state.loans;

  // Pass in named selectors and get state from redux
  const { authToken, user, flow } = useSelector(authState);
  const { config, details } = useSelector(institutionState);
  const { autopay: { payFromDetails }, isFinicityFlow, isAchVerificationComplete, loan } = useSelector(paymentState);
  const { verify, sign } = useSelector(achVerificationState);
  const { getAccounts, saveAccount } = useSelector(savedAccountsState);
  const { autopay } = useSelector(loansState);

  // Local State
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldShowError, setShouldShowError] = useState(false);

  // Hooks
  const dispatch = useDispatch();
  const finicityRef = useRef();
  const nameOnAccountRef = useRef();
  const finicityContainerRef = useRef();
  const navigate = useNavigate();
  const materialTheme = useTheme();
  const nonBankingDays = useNonBankingDays();

  // Filter Accounts
  const results = useMemo(() => getAccounts?.response?.results || [], [getAccounts?.response?.results]);

  useEffect(() => {
    const shouldCheckSavedAccounts = (flow === REGISTERED || flow === REGISTER_NEW_USER);
    if (getAccounts?.response?.found === undefined && shouldCheckSavedAccounts) {
      dispatch(getSavedAccounts(authToken.value));
    }
  }, [getAccounts?.response?.found, authToken.value, dispatch, flow]);

  // Form Hook Declarations
  const formOptions = {
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      paymentAmount: Number(payFromDetails?.payment_amount || loan?.next_amount_due || "0.00").toFixed(2),
      nameOnAccount: payFromDetails?.nameOnAccount || user.account_name,
      startDate: payFromDetails?.starting_date || null,
      endDate: payFromDetails?.endDate || null,
      type: payFromDetails?.type,
      payFromSavedAccount: payFromDetails?.account_id || "",
      payFromAccountDropdown: payFromDetails?.account_id || "",
      isFinicityFlow,
      isRegisteredPath: flow === REGISTERED,
      frequency: payFromDetails?.frequency || "",
      endDateOptions: payFromDetails?.ending || "",
      confirmation: (payFromDetails?.process_after !== undefined && !payFromDetails?.process_after) || false
    }
  };

  // Form Hooks
  const { handleSubmit, formState: { errors }, setValue, getValues, watch, register, trigger } = useForm(formOptions);
  const { errorMessage } = useMultipleFormErrors(errors);

  // Values to Watch
  const watchAccount = watch("payFromAccountDropdown");
  const watchEndDateOptions = watch("endDateOptions");
  const watchPaymentAmount = watch("paymentAmount");
  const watchFrequency = watch("frequency");
  const watchStartDate = watch("startDate");
  const watchEndDate = watch("endDate");
  const watchCheckbox = watch("confirmation");

  // Handlers
  const handleFormSubmit = (data) => {
    const payload = {
      token: authToken?.value,
      loan_id: loan?.id,
      account_id: data?.payFromAccountDropdown,
      ending: data?.endDateOptions,
      frequency: data?.frequency,
      payment_amount: Math.abs(Number(data?.paymentAmount)),
      process_after: !data.confirmation,
      skips: [],
      starting_date: dayjs(data?.startDate).format("MM/DD/YYYY"),
      nameOnAccount: data.nameOnAccount,
      brandDetails: {
        brand_phone: config?.brand_phone,
        brand_name: config?.brand_name
      }
    };

    if (data?.endDateOptions === "on_a_specific_date") {
      payload.endDate = dayjs(data?.endDate).format("MM/DD/YYYY");
    }

    if (currentLoan?.banking_details?.name_on_account !== data?.nameOnAccount) {
      const accountPayload = {
        token: authToken?.value,
        id: data?.payFromAccountDropdown,
        banking_details: {
          name_on_account: data?.nameOnAccount
        }
      };
      dispatch(patchSaveAccount(accountPayload))
        .unwrap()
        .then((response) => {
          if (response?.banking_details?.name_on_account === accountPayload?.banking_details?.name_on_account) {
            dispatch(setAutoPayDetails(payload));
            navigate("/autopay/authorize");
          }
        });
    } else {
      dispatch(setAutoPayDetails(payload));
      navigate("/autopay/authorize");
    }

  };

  // Helpers
  const currentLoan = results?.find(loan => loan.id === watchAccount);
  const loanNameOnAccount = currentLoan?.banking_details?.name_on_account;
  const amountToPay = Number(watchPaymentAmount) || Number(0);
  const convenienceFee = Math.abs(Number(getAutopayConvenienceFee(details, { loan: loan?.type })));
  const total = ((Number(amountToPay) || 0) + Number(convenienceFee));
  const { ach, shouldDisableAutopayAmount } = getPaymentConfig(details, loan?.type, true);

  const isValidDate = (event) => {
    const formattedDate = dayjs(event).format("MM/DD/YYYY");
    const businessDay = !validateIsBusinessDay(dayjs(event));
    const nonBankingDay = nonBankingDays?.includes(formattedDate);
    if (watchFrequency === "monthly") {
      return nonBankingDay;
    }
    return businessDay || nonBankingDay;
  };

  const nextDueDate = (loan?.next_due_date !== null && loan?.next_due_date !== "null")
    ? { title: "Due Date:", value: loan?.next_due_date }
    : null;

  useEffect(() => {
    if (!watchAccount) return undefined;

    setValue("nameOnAccount", loanNameOnAccount, {
      shouldValidate: false,
      shouldDirty: false
    });
    nameOnAccountRef.current.focus();
  }, [watchAccount, setValue, loanNameOnAccount]);

  useEffect(() => {
    if (getAccounts?.response?.results.length === 1) {
      const [account] = getAccounts.response.results;
      setValue("payFromAccountDropdown", account.id);
    }
  }, []);

  useEffect(() => {
    if (saveAccount?.fulfilledAccounts.length === 1) {
      const [account] = saveAccount.fulfilledAccounts;
      setValue("payFromAccountDropdown", account?.value?.id);
      return () => {
        dispatch(setFulfilledAccounts([]));
      };
    }
  }, [saveAccount.fulfilledAccounts]);

  const setModalClosed = (route) => {
    setIsModalOpen(false);
    setIsButtonDisabled(false);
    if (route) {
      navigate(route);
    }
  };

  // Formatters
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const maskedLoanNumber = (loan) => {
    const accountLastFour = getAccountLastFour(loan);
    const getLoanPreFix = loan.replace(/[^accountLastFour/]/g, "*");
    return `${getLoanPreFix}${accountLastFour}`;
  };


  return (
    <Fragment>
      <Title title="Setup Autopay Schedule" />

      {/* All Alerts */}
      <Alert
        message={getAccounts?.error?.response || autopay?.api?.response?.response}
        severity={getAccounts?.error?.severity || autopay?.api?.response?.severity}
      />
      <Alert
        message={autopay?.deleted?.response?.response}
        severity={autopay?.deleted?.response?.severity}
      />
      <Alert message={errorMessage} severity="error" />

      <Alert
        message={isAchVerificationComplete ? "Account(s) successfully linked" : null}
        severity="success"
      />

      <Typography component="p" variant="body2" sx={{ marginBlock: "0.5rem" }}>* Required Field</Typography>

      <Form materialTheme={materialTheme} onSubmit={handleSubmit(handleFormSubmit)} noValidate={true} onBlur={() => dispatch(setActivity())}>
        <section className="inputs-container">
          <fieldset className="fieldset-container">
            <Typography component="legend" variant="h2" sx={{ marginBottom: "0.875rem" }}>
              Payment Details
            </Typography>
            <div style={{ marginBottom: "1rem" }}>
              {
                details?.services?.sdk?.enhanced
                  ? <ButtonElement />
                  :
                  <>
                    <div id="swbc-consumer-experience" tabIndex="-1" ref={finicityContainerRef} />
                    <FinicityService
                      ref={finicityRef}
                      user={user}
                      nameOnAccount={getValues("nameOnAccount")}
                      token={authToken.value}
                      details={details}
                      setValue={setValue}
                      setIsButtonDisabled={setIsButtonDisabled}
                      setIsModalOpen={setIsModalOpen}
                      setShouldShowError={setShouldShowError}
                      flow={flow}
                      entry="setupAutopay"
                      achWebIframe={finicityContainerRef}
                    />

                    <AchModals
                      shouldShowError={shouldShowError}
                      isModalOpen={isModalOpen}
                      setModalClosed={setModalClosed}
                      config={config}
                      entry="setupAutopay"
                    />
                    <Button
                      color="secondary"
                      disabled={
                        getAccounts?.isSubmitting
                        || autopay?.api?.isSubmitting
                        || saveAccount?.isSubmitting
                        || sign?.isLoading
                        || verify?.isLoading
                        || isButtonDisabled
                      }
                      loading={sign?.isLoading || verify?.isLoading || saveAccount?.isSubmitting}
                      onClick={() => {
                        dispatch(setIsAchVerificationComplete(false));
                        dispatch(setActivity());
                        finicityRef.current.openFinicityFlow();
                      }}
                    >
                      CONNECT YOUR BANK
                    </Button>
                  </>
              }
            </div>

            <TextField
              sx={{ zIndex: 0 }}
              id="account-select"
              label="Account"
              size="medium"
              fullWidth
              error={!!errors?.payFromAccountDropdown}
              helperText={errors?.payFromAccountDropdown?.message || " "}
              select
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      boxSizing: "border-box",
                      maxHeight: 200,
                      marginTop: "8px"
                    }
                  },
                  sx: {
                    width: 230
                  }
                }
              }}
              required
              value={results?.length === 0 ? null : watchAccount}
              {...register("payFromAccountDropdown", {
                required: "Please select an account"
              })}
            >
              {
                Array.isArray(results) && results.length > 0 ?
                  results.map((ele, i) => (
                    <MenuItem key={i} value={ele.id} sx={{
                      display: "block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap"
                    }}>
                      {`${ele.banking_details.name_of_bank || "Account"} ending in ${ele.last_4}`}
                    </MenuItem>
                  ))
                  :
                  <MenuItem disabled value={""}>
                    Please connect your bank
                  </MenuItem>
              }
            </TextField>

            <TextField
              sx={{ zIndex: 0 }}
              required
              id="name-on-account"
              label="Name on Account"
              error={!!errors?.nameOnAccount}
              inputProps={{ maxLength: 81 }}
              inputRef={nameOnAccountRef}
              helperText={errors?.nameOnAccount?.message || " "}
              {...register("nameOnAccount", validate.fullName)}
            />
            <FormControl
              disabled={shouldDisableAutopayAmount}
              error={!!errors?.paymentAmount}
              required
            >
              <InputLabel htmlFor="payment-amount" sx={{ zIndex: 0 }}>Payment Amount</InputLabel>
              <OutlinedInput
                autoComplete="transaction-amount"
                disabled={shouldDisableAutopayAmount}
                id="payment-amount"
                label="Payment Amount"
                placeholder={Number(watchPaymentAmount) === 0 ? "0.00" : ""}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                inputProps={{ maxLength: 8 }}
                value={watchPaymentAmount}
                {...register("paymentAmount", {
                  required: "Payment amount must be greater than $1",
                  validate: {
                    isValidFormat: (value) => (Number(value) ? null : "Not a valid amount"),
                    isAmountValid: (value) => validateAmount(value, ach.maxAchAmount, ach.minAchAmount)
                  },
                  onBlur: (e) => paymentAmountInputMask(e, setValue)
                })}
              />
              {
                !shouldDisableAutopayAmount
                  ? <FormHelperText>{errors?.paymentAmount?.message || " "}</FormHelperText>
                  : null
              }
            </FormControl>
            {
              shouldDisableAutopayAmount
                ? <Alert
                  severity="info"
                  message={`Payment Amount of $${formatter.format(amountToPay)} has been pre-set by your bank.`}
                  sx={{ marginBottom: "16px" }}
                />
                : null
            }
          </fieldset>

          <fieldset className="fieldset-container">
            <Typography component="legend" variant="h2" sx={{ marginBottom: "0.875rem" }}>
              Payment Schedule
            </Typography>
            <div className="date-selection-container">
              <TextField
                sx={{ zIndex: 0 }}
                error={!!errors?.frequency}
                helperText={errors?.frequency?.message || " "}
                id="frequency-select"
                label="Frequency"
                size="medium"
                fullWidth
                select
                required
                value={watchFrequency}
                {...register("frequency", {
                  required: "Please select a frequency",
                  validate: async (value) => {
                    value !== "monthly" && await trigger("startDate", { shouldFocus: true });
                    return null;
                  }
                })}
              >
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="every_two_weeks">Every Two Weeks</MenuItem>
              </TextField>

              <Calendar
                label="Start Date"
                control={{
                  ...register("startDate", {
                    required: "Start Date is required",
                    validate: (value) => {
                      const isDateBefore = dayjs(value).isBefore(dayjs());
                      const isBusinessDay = isValidDate(value);
                      if (isBusinessDay || isDateBefore) return "Please choose an available date";
                      return null;
                    }
                  })
                }}
                minDate={dayjs().add(1, "day")}
                errors={errors?.startDate}
                value={dayjs(watchStartDate)}
                shouldDisableDate={(event) => ((watchFrequency !== "monthly") ? isValidDate(event) : null)}
                onChange={
                  (event) => setValue("startDate", event, {
                    shouldValidate: true,
                    shouldDirty: true
                  })
                }
              />
            </div>

            <div className="date-selection-container">
              <TextField
                {...register("endDateOptions", {
                  required: "Please select an end date option"
                })}
                error={!!errors?.endDateOptions}
                helperText={errors?.endDateOptions?.message || " "}
                id="end-date-select"
                label="End Date Options"
                size="medium"
                fullWidth
                select
                required
                value={watchEndDateOptions}
              >
                <MenuItem value='until_you_cancel'>Until you cancel</MenuItem>
                <MenuItem value='on_a_specific_date'>On a specific date</MenuItem>
              </TextField>
              {
                watchEndDateOptions === "on_a_specific_date"
                  ?
                  <Calendar
                    control={{
                      ...register("endDate", {
                        required: (watchEndDateOptions === "on_a_specific_date") && "Date is required",
                        validate: (value) => {
                          const isAfterStartDate = dayjs(value).isAfter(watchStartDate);
                          const isBusinessDay = (watchFrequency !== "monthly") ? isValidDate(value) : null;
                          if (!isAfterStartDate) return "End date must be after start";
                          if (isBusinessDay) return "Please choose an available date";
                          return null;
                        }
                      })
                    }}
                    label="End Date"
                    errors={errors?.endDate}
                    value={dayjs(watchEndDate)}
                    minDate={dayjs(watchStartDate).add(1, "day")}
                    shouldDisableDate={(event) => ((watchFrequency !== "monthly") ? isValidDate(event) : null)}
                    onChange={(event) => setValue("endDate", event, { shouldValidate: true, shouldDirty: true })}
                    onError={
                      (event) => {
                        if (event === "minDate") {
                          setValue("endDate", dayjs(watchStartDate).add(1, "day"), {
                            shouldValidate: true,
                            shouldDirty: true
                          });
                        }
                      }
                    }
                  />
                  : null
              }
            </div>
          </fieldset>
        </section>

        <section className="checkbox-container">
          <Typography sx={{ marginBottom: "0.5rem" }} variant="body1">
            By default, recurring transactions that fall on non-banking days will process
            on the first banking day AFTER the scheduled date. By selecting the box below,
            the transaction will be processed on the closest banking day BEFORE the scheduled date.
          </Typography>

          <FormControlLabel {...register("confirmation")}
            label={<Typography>Process BEFORE?</Typography>}
            control={<Checkbox checked={watchCheckbox} />}
          />
        </section>

        <Summary
          showHeader={true}
          rows={
            [
              { title: "Type:", value: loan?.type.toLowerCase() },
              { title: "Loan:", value: maskedLoanNumber(loan?.number) },
              nextDueDate,
              { title: "Payment Amount:", value: `$${formatter.format(amountToPay)}` },
              { title: "Fees:", value: `$${formatter.format(convenienceFee || "0.00")}` },
              {
                title: "Total Balance:",
                value: `$${formatter.format(total)}`,
                divider: true
              }
            ]
          }
        />

        <section className="buttons-container">
          <Button
            color="primary"
            disabled={getAccounts?.isSubmitting || autopay?.api?.isSubmitting}
            loading={getAccounts?.isSubmitting || autopay?.api?.isSubmitting}
            type="submit"
          >
            Authorize Autopay Schedule
          </Button>
        </section>
      </Form>
    </Fragment >
  );
};
