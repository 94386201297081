import React, { createContext, useContext, useReducer } from "react";
import types from "./actions";

const initialState = {
  applePayPaymentDetails: {},
  paymentDetails: {
    amount: "",
    date: "",
    stateOfResidence: "",
    pendingTransactions: [],
    convenienceFee: 0,
    convenienceFeeSource: "",
    nameOnAccount: "",
    selectedPaymentMethod: {
      billing_city: "",
      billing_state: "",
      billing_street: "",
      billing_zipcode: "",
      card_cvv: "",
      card_expiry_date: "",
      card_holder_name: "",
      card_number: "",
      banking_details: {
        name_of_bank: "",
        name_on_account: "",
        routing_number: "",
        account_number: "",
        account_type: ""
      },
      name_of_bank: "",
      name_on_account: "",
      routing_number: "",
      id: "",
      validation_type: "",
      vendor: "",
      type: "",
      last_4: ""
    }
  },
  loan: {},
  paymentConfirmation: {}

};

const TransactionContext = createContext(initialState);
const TransactionDispatch = createContext();

const transactionReducer = (state, action) => {
  switch (action.type) {
    case types.UPDATE_APPLE_PAY_PAYMENT_DETAILS:
      return {
        ...state,
        applePayPaymentDetails: action.value
      };
    case types.UPDATE_NAME_ON_ACCOUNT:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          nameOnAccount: action.value
        }
      };
    case types.UPDATE_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          ...action.value
        }
      };
    case types.UPDATE_PENDING_TRANSACTIONS:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          pendingTransactions: action.value
        }
      };
    case types.UPDATE_CONVENIENCE_FEE:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          convenienceFee: action.value
        }
      };
    case types.UPDATE_CONVENIENCE_FEE_SOURCE:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          convenienceFeeSource: action.value
        }
      };
    // to be called on submisson of "Add card" submit and clicking on an ACH account in select payment flow
    case types.UPDATE_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          selectedPaymentMethod: action.value
        }
      };
    case types.UPDATE_LOAN:
      return {
        ...state,
        loan: action.value
      };
    case types.UPDATE_CONFIRMATION:
      return {
        ...state,
        paymentConfirmation: action.value
      };
    default:
      break;
  }
};

const useTransactionContext = () => {
  const context = useContext(TransactionContext);
  if (context === undefined) {
    throw new Error("TransactionContext must be used within TransactionProvider");
  }
  return context;
};

const useTransactionDispatch = () => {
  const context = useContext(TransactionDispatch);
  if (context === undefined) {
    throw new Error("TransactionDispatch must be used within TransactionProvider");
  }
  return context;
};

const TransactionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(transactionReducer, initialState);
  return (
    <TransactionContext.Provider value={state}>
      <TransactionDispatch.Provider value={dispatch}>
        {children}
      </TransactionDispatch.Provider>
    </TransactionContext.Provider>
  );
};

export {
  useTransactionContext,
  useTransactionDispatch,
  TransactionProvider,
  types
};
