export const sortAccountsAndCards = (accounts, cards) => {
  accounts?.sort((a, b) => {
    if (a.banking_details.name_of_bank < b.banking_details.name_of_bank) return -1;
    if (a.banking_details.name_of_bank > b.banking_details.name_of_bank) return 1;
    return 0;
  });
  cards?.sort((a, b) => {
    if (a.card_details.card_brand < b.card_details.card_brand) return -1;
    if (a.card_details.card_brand > b.card_details.card_brand) return 1;
    return 0;
  });

  return { accounts, cards };
};

export const formatCardExpiryDate = (expiryMonth, expiryYear) => {
  const month = expiryMonth.toString().padStart(2, "0");
  const year = expiryYear.toString().slice(-2);

  return `${month}/${year}`;
};

export const bankListMapper = (accounts) => accounts?.map((item) => {
  return {
    id: item?.id,
    paymentType: "ach",
    primaryText: item?.banking_details?.name_of_bank || "Account",
    secondaryText: item?.last_4,
    details: {
      ...item,
      label: item?.banking_details?.name_of_bank,
      type: "ach"
    }
  };
});
