const types = {
  UPDATE_APPLE_PAY_PAYMENT_DETAILS: "updateApplePayPaymentDetails",
  UPDATE_PAYMENT_DETAILS: "updatePaymentDetails",
  UPDATE_PENDING_TRANSACTIONS: "updatePendingTransactions",
  UPDATE_CONVENIENCE_FEE: "updateConvenienceFee",
  UPDATE_CONVENIENCE_FEE_SOURCE: "updateConvenienceFeeSource",
  UPDATE_SELECTED_PAYMENT_METHOD: "updateSelectedPaymentMethod",
  UPDATE_LOAN: "updateLoan",
  UPDATE_CONFIRMATION: "updateConfirmation",
  UPDATE_NAME_ON_ACCOUNT: "updateNameOnAccount"
};

export default types;
