import React, { useState } from "react";
import { Stack } from "@mui/material";
import { PaymentSourceSelectionList, List } from "@swbc/swivel-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components";
import { Typography, Grid, Divider, Alert, LoadingIndicator } from "../../components/mui";
import { deleteAccount } from "../../services/accounts/api/deleteAccount.js";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics.js";
import { useHandleDeleteSavedPaymentMethod, useAccountsAndCardsData } from "../../services/payments/lib/hooks.js";
import { ButtonElement } from "../../services/sdk/components/ButtonElement.js";
import { deleteCard } from "../../services/storedCards/api/deleteCard.js";
import { sortAccountsAndCards, formatCardExpiryDate, bankListMapper } from "../../utils/helpers/accountsAndCardsHelpers.js";

export const ProfileSavedAccounts = () => {
  const institutionState = (state) => state.institution;

  const { details } = useSelector(institutionState);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { actions, categories, trackGtagEvent } = useGoogleAnalytics();

  const [error, setError] = useState(null);

  const isStoredCardsEnabled = details?.services?.payments?.card?.enabled && details?.services?.payments?.card?.stored_cards?.enabled;

  const {
    data: accountsAndCardsData,
    refetch,
    isLoading: accountsAndCardsLoading,
    isRefetching: accountsAndCardRefetching
  } = useAccountsAndCardsData(setError);


  const handleAddPaymentMethod = () => {
    navigate("/add-or-edit-payment-method");
  };

  const cardMutation = useMutation({
    mutationFn: deleteCard,
    onSuccess: async () => {
      trackGtagEvent(actions.DELETE_STORED_CARD, categories.ATTEMPT_SUCCESS);
      achMutation.reset();
      await refetch();
      queryClient.invalidateQueries(["getAccountsAndCards"]);
    },
    onError: (error) => {
      trackGtagEvent(actions.DELETE_STORED_CARD, categories.ATTEMPT_FAILURE);
      setError(error);
    }
  });

  const achMutation = useMutation({
    mutationFn: deleteAccount,
    onSuccess: () => {
      cardMutation.reset();
      refetch();
      queryClient.invalidateQueries(["getAccountsAndCards"]);
    },
    onError: (error) => {
      setError(error);
    }
  });

  const deleteSavedPaymentMethod = useHandleDeleteSavedPaymentMethod({ error, setError, cardMutation, achMutation });

  const { accounts, cards } = sortAccountsAndCards(accountsAndCardsData?.accounts?.results, accountsAndCardsData?.cards?.results);

  const cardList = isStoredCardsEnabled ? cards?.map((item) => {
    const date = formatCardExpiryDate(item.card_details.expiration_month, item.card_details.expiration_year);

    return {
      id: item?.id,
      paymentType: "card",
      cardBrand: item?.display_name,
      cardExpiry: date,
      primaryText: item?.display_name,
      secondaryText: item?.card_details?.card_last4,
      details: {
        ...item,
        label: item?.display_name,
        type: "card",
        last_4: item?.card_details?.card_last4
      }
    };
  }) : [];

  const bankList = bankListMapper(accounts);

  return (
    <div
      style={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        paddingTop: "1rem"
      }}
    >
      <Typography variant="h2" component="h2">Saved Payment Methods</Typography>
      <Stack
        divider={<Divider sx={{ width: "100%", margin: "auto" }} aria-hidden="true" />}
        sx={{ margin: "0px", padding: "0px", display: "grid" }}
      >
        {accountsAndCardsLoading || accountsAndCardRefetching || cardMutation.isLoading || achMutation.isLoading ? (
          <LoadingIndicator titleVariant="h2" />
        ) : (
          <>
            <Alert message={cardMutation?.isSuccess && cardMutation?.data?.message} severity={cardMutation?.status} />
            <Alert message={achMutation?.isSuccess && achMutation?.data?.message} severity={achMutation?.status} />
            <Alert message={error?.message} severity={error?.severity} />
            {
              (bankList && !bankList.length) && (cardList && !cardList.length)
                ? <Alert message="No saved payment methods have been created" severity="info" />
                : null
            }
            <List sx={{ padding: "0", border: (bankList?.length || cardList?.length) ? "2px solid #ddd" : 0, borderRadius: "5px" }}>
              {
                bankList?.map((account, index) => (
                  <PaymentSourceSelectionList
                    key={account.id}
                    id={account.id}
                    paymentType={account.paymentType}
                    primaryText={account.primaryText || "Account"}
                    secondaryText={account.secondaryText}
                    deletePaymentMethod={() => deleteSavedPaymentMethod(account)}
                    loadingItemId={null}
                    selectable={false}
                    selected={null}
                    isLastItem={cardList.length < 1 && index === bankList.length - 1}
                  />
                ))
              }
              {
                cardList?.map((card, index) => {
                  return (
                    <PaymentSourceSelectionList
                      key={card.id}
                      id={card.id}
                      paymentType={card.paymentType}
                      primaryText={card.primaryText}
                      secondaryText={card.secondaryText}
                      cardBrand={card.cardBrand}
                      cardExpiry={card.cardExpiry}
                      deletePaymentMethod={() => deleteSavedPaymentMethod(card)}
                      loadingItemId={null}
                      selectable={false}
                      selected={null}
                      isLastItem={index === cardList.length - 1}
                    />
                  );
                })
              }
            </List>
            <Grid item xs={12} paddingTop="1.5rem" container spacing={1} direction="row-reverse">
              <Grid item xs={12} md={6}>
                {isStoredCardsEnabled ?
                  <Button
                    color="secondary"
                    onClick={handleAddPaymentMethod}
                  >
                    ADD PAYMENT METHOD
                  </Button> :
                  <ButtonElement />
                }
              </Grid>
            </Grid>
          </>
        )}
      </Stack>
    </div >
  );
};
