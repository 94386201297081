import React from "react";
import { creditCardBrand, CreditCardForm, MaskTextField } from "@swbc/swivel-components";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert } from "../../../components";
import Title from "../../../components/Title";
import { useTransactionDispatch, transactionTypes, useSelectPaymentMethodDispatch, selectPaymentMethodTypes } from "../../../context";
import { useGoogleAnalytics } from "../../../services/analytics/hooks/useGoogleAnalytics";
import { constructSaveCardPayload } from "../../../services/payments/lib/constructSaveCardPayload";
import { useAccountsAndCardsData } from "../../../services/payments/lib/hooks";
import { postCard } from "../../../services/storedCards/api/postCard";
import { REGISTERED } from "../../../utils/constants/constants";
import { getAccountLastFour } from "../../../utils/helpers/handleAccounts";

const AddCardPage = () => {

  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;

  const { details } = useSelector(institutionState);
  const { flow, authToken } = useSelector(authState);
  const { actions, categories, trackGtagEvent } = useGoogleAnalytics();

  const selectPaymentDispatch = useSelectPaymentMethodDispatch();
  const dispatch = useTransactionDispatch();
  const navigate = useNavigate();

  const { data: accountsAndCardsData } = useAccountsAndCardsData();

  const mutation = useMutation({
    mutationFn: postCard,
    onSuccess: (data) => {
      const cardData = {
        type: "card",
        last_4: data?.card_details?.card_last4,
        label: data?.display_name,
        ...data
      };
      dispatch({
        type: transactionTypes.UPDATE_SELECTED_PAYMENT_METHOD,
        value: cardData
      });
      selectPaymentDispatch({
        type: selectPaymentMethodTypes.UPDATE_AVAILABLE_CARD,
        value: [cardData]
      });
      trackGtagEvent(actions.STORE_CARD, categories.ATTEMPT_SUCCESS);
      navigate("/make-payment");
    },
    onError: (error) => {
      trackGtagEvent(actions.STORE_CARD, categories.ATTEMPT_FAILURE, { event_details: error?.message });
    }
  });

  const handleAddCardSubmit = (form) => {
    const { data } = form;
    const cardBrand = creditCardBrand(data.card_number);

    if (data.store_card) {
      trackGtagEvent(actions.BUTTON_CLICK, categories.STORE_CARD_SELECT);
      const payload = constructSaveCardPayload(data, authToken?.value);
      mutation.mutate(payload);
    } else {
      const cardData = {
        type: "card",
        last_4: getAccountLastFour(data.card_number),
        label: cardBrand,
        ...data
      };
      dispatch({
        type: transactionTypes.UPDATE_SELECTED_PAYMENT_METHOD,
        value: cardData
      });
      selectPaymentDispatch({
        type: selectPaymentMethodTypes.UPDATE_AVAILABLE_CARD,
        value: [cardData]
      });
      navigate("/make-payment");
    }
  };

  const acceptedCardTypes = details?.accepted_card_types;
  const nativeBinList = details?.native_bin_list;
  const nativeBinListError = details?.native_bin_list_error_text;
  const isStoredCardsEnabled = details?.services?.payments?.card?.enabled && details?.services?.payments?.card?.stored_cards?.enabled;
  const displayCheckbox = flow === REGISTERED && isStoredCardsEnabled;
  const maxCardsReached = isStoredCardsEnabled && accountsAndCardsData?.cards?.results.length > 4;

  return (
    <>
      <Title title="Add Card Details" />
      <Alert message={mutation?.isError && mutation?.error?.response} severity={mutation?.error?.severity} />
      <CreditCardForm
        DateField={({ value, ...props }) => <MaskTextField mask="00/00" InputProps={{ value: typeof value === "string" ? value : "" }}  {...props} />}
        disableGutters={true}
        onSubmit={handleAddCardSubmit}
        displayCheckbox={displayCheckbox}
        isLoading={mutation.isLoading}
        maxCardsReached={maxCardsReached}
        options={{
          whitelistCreditCardBrands: acceptedCardTypes,
          blacklistCreditCardBins: nativeBinList,
          blacklistCreditCardBinsErrorMessage: nativeBinListError
        }}
      />
    </>
  );
};

export default AddCardPage;
