import axios from "axios";
import { patchSaveAccountResponses } from "../../../utils/data/responseCodeMessages";

const { REACT_APP_API_URL } = process.env;

export const updateAccount = async (payload) => {
  const url = `${REACT_APP_API_URL}/account/${payload?.id}`;
  const header = { headers: { Authorization: payload?.token } };
  delete payload?.token;

  const response = await axios
    .patch(url, payload, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      const status = error?.response?.status || error?.request?.status;
      const data = error?.response?.data;
      const errorResponse = patchSaveAccountResponses()[status]
        || patchSaveAccountResponses()[data.errorMessage]
        || patchSaveAccountResponses()["default"];
      return errorResponse;
    });

  return response;
};
