import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { AlertTitle, ListItem, useTheme } from "@mui/material";
import { Button, Typography } from "@swbc/swivel-components";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Box, List, ListItemIcon, ListItemText } from "../../../components";
import Title from "../../../components/Title";
import { transactionTypes, useTransactionContext, useTransactionDispatch } from "../../../context";
import { useGoogleAnalytics } from "../../../services/analytics/hooks/useGoogleAnalytics";
import { postV2 } from "../../../services/payments/api/postV2";
import { postVendor } from "../../../services/payments/api/postVendor";
import { constructACHPayload } from "../../../services/payments/lib/constructACHPayload";
import { constructApplePayPayload } from "../../../services/payments/lib/constructApplePayPayload";
import { constructCardPayload } from "../../../services/payments/lib/constructCardPayload";
import { STORED_CARD } from "../../../utils/constants/constants";
import { currentDate } from "../../../utils/helpers/dayjsHelpers";
import { getAccountLastFour } from "../../../utils/helpers/handleAccounts";
import { ButtonContainer } from "./index.styles";

const PendingPayment = () => {

  const paymentState = (state) => state.payment;
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;

  const { loan } = useSelector(paymentState);
  const { config } = useSelector(institutionState);
  const { authToken, flow } = useSelector(authState);
  const { paymentDetails, applePayPaymentDetails } = useTransactionContext();
  const { trackEvent, categories, gaPaymentTypeEvent, trackGtagEvent } = useGoogleAnalytics();

  const navigate = useNavigate();
  const materialTheme = useTheme();
  const dispatch = useTransactionDispatch();

  const transactionVerbiage = paymentDetails?.pendingTransactions.length < 2 ? "payment" : "payments";
  const isCardPayment = paymentDetails?.selectedPaymentMethod?.type === "card";
  const isStoredCardPayment = isCardPayment && !!paymentDetails?.selectedPaymentMethod?.id;
  const isPaymentMethodApplePay = paymentDetails.selectedPaymentMethod.type === "apple";
  const selectedPaymentType = paymentDetails?.selectedPaymentMethod?.type;

  const mutation = useMutation({
    mutationFn: postV2,
    onSuccess: (data) => {
      if (data.tracking) {
        dispatch({ type: transactionTypes.UPDATE_CONFIRMATION, value: { number: data?.tracking, time: currentDate } });
        trackEvent(gaPaymentTypeEvent(isStoredCardPayment ? STORED_CARD : selectedPaymentType, true), categories.ATTEMPT_SUCCESS);
        navigate("/payment-confirmed");
      } else {
        dispatch({ type: transactionTypes.UPDATE_PENDING_TRANSACTIONS, value: data?.PendingTransactions });
      }
    },
    onError: (error) => {
      trackGtagEvent(
        gaPaymentTypeEvent(
          isStoredCardPayment ? STORED_CARD : selectedPaymentType, true),
        categories.ATTEMPT_FAILURE,
        { event_details: error?.message }
      );
      throw new Error(error);
    }
  });

  const applePayMutation = useMutation({
    mutationFn: postVendor,
    onSuccess: (data) => {
      if (data?.tracking) {
        dispatch({ type: transactionTypes.UPDATE_APPLE_PAY_PAYMENT_DETAILS, value: {} });
        dispatch({ type: transactionTypes.UPDATE_CONFIRMATION, value: { number: data?.tracking, time: currentDate } });
        trackEvent(gaPaymentTypeEvent(selectedPaymentType, true), categories.ATTEMPT_SUCCESS);
        navigate("/payment-confirmed");
      } else {
        dispatch({ type: transactionTypes.UPDATE_PENDING_TRANSACTIONS, value: data?.PendingTransactions });
      }
    },
    onError: (error) => {
      trackGtagEvent(gaPaymentTypeEvent(selectedPaymentType, true), categories.ATTEMPT_FAILURE, { event_details: error?.message });
    }
  });

  const payload = isCardPayment
    ? constructCardPayload(paymentDetails, loan, authToken?.value, config)
    : constructACHPayload(paymentDetails, loan?.id, authToken?.value, loan?.idempotencyToken, flow, paymentDetails?.convenienceFee, config);

  const submitPayment = () => {
    if (isPaymentMethodApplePay) {
      const applePayload = constructApplePayPayload(paymentDetails, loan, authToken.value, applePayPaymentDetails, config);
      applePayMutation.mutate(applePayload);
    } else {
      mutation.mutate(payload);
    }
  };

  const getPaymentItem = (submissionDate, amount) => {
    const creationDate = dayjs(submissionDate).format("MMMM DD YYYY");
    const paymentAmount = amount ? amount.toFixed(2) : 0;
    const message = `${creationDate} - $ ${paymentAmount}`;
    return message;
  };

  const styles = {
    border: "2px solid orange",
    margin: "2rem 0px",
    display: "block"
  };

  return (
    <>
      <Title title="Pending Payments" />
      <Alert message={mutation?.isError && mutation?.error?.response} severity={mutation?.error?.severity} />
      <Alert message={applePayMutation?.isError && applePayMutation?.error?.response} severity={applePayMutation?.error?.severity} />
      <Box component="section" sx={{ width: "100%" }}>
        <Alert
          color="warning"
          icon={false}
          role="status"
          severity="warning"
          sx={styles}
          disableIcon
        >
          <AlertTitle>
            <Box sx={{ display: "flex" }}>
              <WarningAmberIcon
                color="warning"
                fontSize="large"
                sx={{ margin: ".5rem" }}
              />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography component="h2" variant="h3">
                  {loan?.type} account ending in {getAccountLastFour(loan?.number)}
                </Typography>
              </Box>
            </Box>
            <Typography variant="subtitle1" component="p">
              It looks like you have {paymentDetails?.pendingTransactions?.length} pending {transactionVerbiage} for this account.
            </Typography>
          </AlertTitle>
          <List component="ul">
            {paymentDetails.pendingTransactions.map((trans, i) => (
              <ListItem key={i} disableGutters>
                <ListItemIcon sx={{ minWidth: "1.5rem" }}>
                  <CircleIcon color="warning" sx={{ fontSize: ".8rem" }} />
                </ListItemIcon>
                <ListItemText component="li">
                  {getPaymentItem(trans.OriginationDate, trans.Amount)}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Alert>
      </Box>
      <ButtonContainer materialTheme={materialTheme}>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          size="large"
          aria-label="Click to confirm the payment after reviewing the pending payments."
          onClick={submitPayment}
          loading={mutation.isLoading || applePayMutation.isLoading}
        >
          Continue to pay
        </Button>
        <Button
          variant="contained"
          fullWidth
          color="secondary"
          size="large"
          aria-label="Click to cancel payment"
          onClick={() => {
            trackEvent(gaPaymentTypeEvent(selectedPaymentType, true), categories.ATTEMPT_ABANDONED);
            navigate("/accounts");
          }}
        >
          Cancel payment
        </Button>
      </ButtonContainer>
    </>
  );
};

export default PendingPayment;
